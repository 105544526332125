import { atom, useRecoilState } from 'recoil';

import { useConfirm } from 'components/confirm/use-confirm';
import { useI18n } from 'providers/i18n/use-i18n';

import type { UseEditingMethods } from './use-editing-context';

const editingKeyState = atom<string | null>({
    key: 'EditingKeyState',
    default: null,
});

export const useEditing = (): UseEditingMethods => {
    const [editingKey, setEditingKeyState] = useRecoilState(editingKeyState);
    const { open: openConfirm } = useConfirm();
    const { i18n } = useI18n();

    const setEditingKey = (
        newEditingKey: string,
        onConfirmChangeCallback?: (newEditingKey: string) => Promise<void>
    ) => {
        const handleConfirmChangeEdit = async () => {
            if (onConfirmChangeCallback) {
                await onConfirmChangeCallback(newEditingKey);
            }
            setEditingKeyState(newEditingKey);
        };

        if (newEditingKey === editingKey) return;

        if (newEditingKey && editingKey !== null) {
            openConfirm({
                txt: i18n._('confirmChangeEditing'),
                onCancel: undefined,
                onConfirm: handleConfirmChangeEdit,
                confirmLabel: i18n._('ContinueAndLoseChanges'),
                cancelLabel: i18n._('CancelAndEdit'),
            });

            return;
        }

        handleConfirmChangeEdit();
    };

    const clearEditingKey = () => {
        setEditingKeyState(null);
    };

    return {
        setEditingKey,
        editingKey,
        clearEditingKey,
    };
};

// If we need to add the section name in the text
// editingKey format are these.
// Register Address: address-${address.id}
// Applicant Info: applicantInfo-${applicantId}
// Employment:  incomeKey-${employment.id}
// Other Income: otherIncome-${otherIncome.id}
// Other Properties: ownedProperty-${property.id}
// assets:  asset-${applicantId}-${asset.id}
// Subject Property: subjectProperty
// Mortgage Details: mortgageDetails
